import { useTheme } from '@emotion/react';

export const RestaurantInMagIllustration = () => {
  const theme = useTheme();

  const stronger = theme.customization.colors.cta.primary.pressed;
  const neutral = theme.customization.colors.cta.primary.default;
  const weaker = theme.customization.colors.cta.primary.hover;
  const shadow = theme.customization.colors.cta.secondary.hover;

  return (
    <svg width="173px" height="186px" viewBox="0 0 173 186" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="illustration-no-result" transform="translate(-32.000000, 6.000000)">
          <rect id="fake-block" x="0" y="9.89010989" width="37.6363636" height="143.406593"></rect>
          <g id="magnifying-glass" transform="translate(34.636364, 0.000000)">
            <g id="glass">
              <g id="resto-02" opacity="0.599999964" transform="translate(49.243869, 36.429453)">
                <g id="Group-2">
                  <path
                    d="M 33.7317 93.2949 L 2.3319 93.2949 C 1.9386 93.2949 1.6198 92.9761 1.6198 92.5829 L 1.6198 23.2993 C 1.6198 22.906 1.9386 22.5872 2.3319 22.5872 L 2.3319 22.5872 L 84.7483 22.5872 C 80 58 74 78 34 93"
                    id="Path"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                    fill="#F7F7F7"
                  ></path>
                  <g id="roof" transform="translate(0.915019, 0.000000)">
                    <g id="Group-Copy-5" transform="translate(76.150195, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={weaker}
                        points="3 23.5692481 8.83003891 23.5692481 3.83003891 0 9.86480299e-14 0"
                      ></polygon>
                      <path
                        d="M9.91501945,31.4256641 C5.54317567,31.4256641 3,27.9087224 3,23.5692481 L9.206409,23.5692481 C8.80770582,27.4974561 9.206409,29.5705471 9.91501945,31.4256641 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-4" transform="translate(63.320156, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={stronger}
                        points="0 23.5692481 15.8300389 23.5692481 15.8300389 0 0 0"
                      ></polygon>
                      <path
                        d="M7.91501945,31.4256641 C3.54317567,31.4256641 0,27.9087224 0,23.5692481 L15.8300389,23.5692481 C15.8300389,27.9087224 12.2868632,31.4256641 7.91501945,31.4256641 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-3" transform="translate(47.490117, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={weaker}
                        points="0 23.5692481 15.8300389 23.5692481 15.8300389 0 0 0"
                      ></polygon>
                      <path
                        d="M7.91501945,31.4256641 C3.54317567,31.4256641 0,27.9087224 0,23.5692481 L15.8300389,23.5692481 C15.8300389,27.9087224 12.2868632,31.4256641 7.91501945,31.4256641 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-2" transform="translate(31.660078, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={stronger}
                        points="0 23.5692481 15.8300389 23.5692481 15.8300389 0 0 0"
                      ></polygon>
                      <path
                        d="M7.91501945,31.4256641 C3.54317567,31.4256641 0,27.9087224 0,23.5692481 L15.8300389,23.5692481 C15.8300389,27.9087224 12.2868632,31.4256641 7.91501945,31.4256641 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy" transform="translate(15.830039, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={weaker}
                        points="0 23.5692481 15.8300389 23.5692481 15.8300389 0 0 0"
                      ></polygon>
                      <path
                        d="M7.91501945,31.4256641 C3.54317567,31.4256641 0,27.9087224 0,23.5692481 L15.8300389,23.5692481 C15.8300389,27.9087224 12.2868632,31.4256641 7.91501945,31.4256641 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group">
                      <polygon
                        id="Fill-10"
                        fill={stronger}
                        points="0 23.5692481 15.8300389 23.5692481 15.8300389 0 0 0"
                      ></polygon>
                      <path
                        d="M7.91501945,31.4256641 C3.54317567,31.4256641 0,27.9087224 0,23.5692481 L15.8300389,23.5692481 C15.8300389,27.9087224 12.2868632,31.4256641 7.91501945,31.4256641 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                  </g>
                  <path
                    d="M74.1289494,61.7892971 L74.1289494,39.9941681 C74.1289494,39.6008928 74.447762,39.2820801 74.8410373,39.2820801 L74.8410373,39.2820801 L83.2184003,39.2820801"
                    id="Path"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                  ></path>
                  <path
                    d="M81.4133047,53.0295653 L90.5949911,53.0295653 C90.7916287,53.0295653 90.951035,53.1889716 90.951035,53.3856092 L90.951035,53.6555733 C90.951035,53.852211 90.7916287,54.0116173 90.5949911,54.0116173 L81.4133047,54.0116173 C81.216667,54.0116173 81.0572607,53.852211 81.0572607,53.6555733 L81.0572607,53.3856092 C81.0572607,53.1889716 81.216667,53.0295653 81.4133047,53.0295653 Z"
                    id="Rectangle-8"
                    fill="#FFFFFF"
                    transform="translate(86.004148, 53.524071) rotate(-50.000000) translate(-86.004148, -53.524071) "
                  ></path>
                  <path
                    d="M85.3708144,60.8859813 L91.5843685,60.8859813 C91.7810061,60.8859813 91.9404125,61.0453876 91.9404125,61.2420253 L91.9404125,61.5119894 C91.9404125,61.708627 91.7810061,61.8680333 91.5843685,61.8680333 L85.3708144,61.8680333 C85.1741767,61.8680333 85.0147704,61.708627 85.0147704,61.5119894 L85.0147704,61.2420253 C85.0147704,61.0453876 85.1741767,60.8859813 85.3708144,60.8859813 Z"
                    id="Rectangle-8-Copy-2"
                    fill="#FFFFFF"
                    transform="translate(88.477591, 61.380487) rotate(-50.000000) translate(-88.477591, -61.380487) "
                  ></path>
                  <path
                    d="M85.4487882,52.0557157 L97.5007291,52.0557157 C97.6973668,52.0557157 97.8567731,52.215122 97.8567731,52.4117597 L97.8567731,52.6817238 C97.8567731,52.8783614 97.6973668,53.0377677 97.5007291,53.0377677 L85.4487882,53.0377677 C85.2521505,53.0377677 85.0927442,52.8783614 85.0927442,52.6817238 L85.0927442,52.4117597 C85.0927442,52.215122 85.2521505,52.0557157 85.4487882,52.0557157 Z"
                    id="Rectangle-8-Copy"
                    fill="#FFFFFF"
                    transform="translate(91.474759, 52.550221) rotate(-50.000000) translate(-91.474759, -52.550221) "
                  ></path>
                  <path
                    d="M9.54212682,39.9941681 L9.54212682,72.9418124 L58.5762,72.9418124 L58.5762,39.9941681 L9.54212682,39.9941681 Z"
                    id="Rectangle-7-Copy"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                    fill="#F7F7F7"
                  ></path>
                  <rect
                    id="Rectangle"
                    fill={stronger}
                    x="16.7450584"
                    y="54.0128602"
                    width="1.97875486"
                    height="18.6589881"
                    rx="0.989377432"
                  ></rect>
                  <path
                    d="M50.1066015,54.0128602 L50.6611806,54.0128602 C51.0544559,54.0128602 51.3732685,54.3316728 51.3732685,54.7249481 L51.3732685,71.9597604 C51.3732685,72.3530357 51.0544559,72.6718483 50.6611806,72.6718483 L50.1066015,72.6718483 C49.7133262,72.6718483 49.3945136,72.3530357 49.3945136,71.9597604 L49.3945136,54.7249481 C49.3945136,54.3316728 49.7133262,54.0128602 50.1066015,54.0128602 Z"
                    id="Rectangle-Copy"
                    fill={stronger}
                  ></path>
                  <rect
                    id="Rectangle-2"
                    fill={stronger}
                    x="23.6707004"
                    y="66.7795363"
                    width="20.7769261"
                    height="1.96410401"
                    rx="0.982052004"
                  ></rect>
                  <polygon
                    id="Rectangle-3"
                    fill={stronger}
                    points="32.5750973 67.7615883 35.5432296 67.7615883 35.5432296 73.6539003 32.5750973 73.6539003"
                  ></polygon>
                </g>
              </g>
              <g id="resto-01" transform="translate(0.452677, 69.660222)">
                <g id="Group-2" transform="translate(0.336146, 0.000000)">
                  <path
                    d="M2.86582988,16.3488276 L70.8658299,16.3488276 L70.8658299,61.5277664 C48.494778,59.2885171 33.5085667,54.9455808 25.907196,48.4989576"
                    id="Path"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                    fill="#F7F7F7"
                  ></path>
                  <g id="roof" transform="translate(0.701028, 0.000000)">
                    <g id="Group-Copy-6" transform="translate(59.412336, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={stronger}
                        points="0 17.0596462 11.402056 17.0596462 11.402056 0 0 0"
                      ></polygon>
                      <path
                        d="M5.701028,22.746195 C2.55207758,22.746195 0,20.2005991 0,17.0596462 L11.402056,17.0596462 C11.402056,20.2005991 8.84997842,22.746195 5.701028,22.746195 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-5" transform="translate(48.010280, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={weaker}
                        points="0 17.0596462 11.402056 17.0596462 11.402056 0 0 0"
                      ></polygon>
                      <path
                        d="M5.701028,22.746195 C2.55207758,22.746195 0,20.2005991 0,17.0596462 L11.402056,17.0596462 C11.402056,20.2005991 8.84997842,22.746195 5.701028,22.746195 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-4" transform="translate(36.608224, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={stronger}
                        points="0 17.0596462 11.402056 17.0596462 11.402056 0 0 0"
                      ></polygon>
                      <path
                        d="M5.701028,22.746195 C2.55207758,22.746195 0,20.2005991 0,17.0596462 L11.402056,17.0596462 C11.402056,20.2005991 8.84997842,22.746195 5.701028,22.746195 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-3" transform="translate(25.206168, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={weaker}
                        points="0 17.0596462 11.402056 17.0596462 11.402056 0 0 0"
                      ></polygon>
                      <path
                        d="M5.701028,22.746195 C2.55207758,22.746195 0,20.2005991 0,17.0596462 L11.402056,17.0596462 C11.402056,20.2005991 8.84997842,22.746195 5.701028,22.746195 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy-2" transform="translate(13.804112, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={stronger}
                        points="0 17.0596462 11.402056 17.0596462 11.402056 0 0 0"
                      ></polygon>
                      <path
                        d="M5.701028,22.746195 C2.55207758,22.746195 0,20.2005991 0,17.0596462 L11.402056,17.0596462 C11.402056,20.2005991 8.84997842,22.746195 5.701028,22.746195 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                    <g id="Group-Copy" transform="translate(0.402056, 0.000000)">
                      <polygon
                        id="Fill-10"
                        fill={weaker}
                        points="0.402055998 17.0596462 13.402056 17.0596462 13.402056 0 0.402055998 0"
                      ></polygon>
                      <path
                        d="M7.701028,22.746195 C4.55207758,22.746195 2,20.2005991 2,17.0596462 L13.402056,17.0596462 C13.402056,20.2005991 10.8499784,22.746195 7.701028,22.746195 Z"
                        id="Fill-11"
                        fill={neutral}
                      ></path>
                    </g>
                  </g>
                  <path
                    d="M44.435537,58.1312818 L44.435537,30.4327437 C44.435537,29.3281742 45.3309675,28.4327437 46.435537,28.4327437 L46.435537,28.4327437 L63.814392,28.4327437 C64.9189615,28.4327437 65.814392,29.3281742 65.814392,30.4327437 L65.814392,61.0678052"
                    id="Path"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                  ></path>
                  <path
                    d="M47.9981389,31.988106 L47.9981389,49.0452136 L62.2517901,49.0452136 L62.2517901,31.988106 L47.9981389,31.988106 Z"
                    id="Rectangle-4"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                    fill="#F0F0F0"
                  ></path>
                  <path
                    d="M49.8860526,38.3345171 L56.301519,38.3345171 C56.4978061,38.3345171 56.6569283,38.4936393 56.6569283,38.6899264 C56.6569283,38.8862136 56.4978061,39.0453357 56.301519,39.0453357 L49.8860526,39.0453357 C49.6897655,39.0453357 49.5306433,38.8862136 49.5306433,38.6899264 C49.5306433,38.4936393 49.6897655,38.3345171 49.8860526,38.3345171 Z"
                    id="Rectangle-8"
                    fill="#FFFFFF"
                    transform="translate(53.093786, 38.829023) rotate(-50.000000) translate(-53.093786, -38.829023) "
                  ></path>
                  <path
                    d="M52.7365666,44.0210659 L57.0141475,44.0210659 C57.2104346,44.0210659 57.3695568,44.1801881 57.3695568,44.3764752 C57.3695568,44.5727623 57.2104346,44.7318845 57.0141475,44.7318845 L52.7365666,44.7318845 C52.5402795,44.7318845 52.3811573,44.5727623 52.3811573,44.3764752 C52.3811573,44.1801881 52.5402795,44.0210659 52.7365666,44.0210659 Z"
                    id="Rectangle-8-Copy-2"
                    fill="#FFFFFF"
                    transform="translate(54.875357, 44.515571) rotate(-50.000000) translate(-54.875357, -44.515571) "
                  ></path>
                  <path
                    d="M52.7927295,37.6296355 L61.2755821,37.6296355 C61.4718692,37.6296355 61.6309914,37.7887577 61.6309914,37.9850448 C61.6309914,38.181332 61.4718692,38.3404541 61.2755821,38.3404541 L52.7927295,38.3404541 C52.5964424,38.3404541 52.4373202,38.181332 52.4373202,37.9850448 C52.4373202,37.7887577 52.5964424,37.6296355 52.7927295,37.6296355 Z"
                    id="Rectangle-8-Copy"
                    fill="#FFFFFF"
                    transform="translate(57.034156, 38.124141) rotate(-50.000000) translate(-57.034156, -38.124141) "
                  ></path>
                  <path
                    d="M47.286051,59.336604 L47.286051,51.8910266 C47.286051,51.4977513 47.6048636,51.1789387 47.9981389,51.1789387 L47.9981389,51.1789387 L53.7002481,51.1789387 C54.0935234,51.1789387 54.412336,51.4977513 54.412336,51.8910266 L54.412336,60.6847486"
                    id="Path"
                    stroke={stronger}
                    fill="#F0F0F0"
                  ></path>
                  <path
                    d="M55.837593,60.6224927 L55.837593,51.8910266 C55.837593,51.4977513 56.1564056,51.1789387 56.5496809,51.1789387 L56.5496809,51.1789387 L62.2517901,51.1789387 C62.6450654,51.1789387 62.963878,51.4977513 62.963878,51.8910266 L62.963878,60.747981"
                    id="Path"
                    stroke={stronger}
                    fill="#F0F0F0"
                  ></path>
                  <path
                    d="M8.402056,28.4327437 L31.7461095,28.4327437 C32.850679,28.4327437 33.7461095,29.3281742 33.7461095,30.4327437 L33.7461095,53.3113945"
                    id="Path"
                    stroke={stronger}
                    strokeWidth="1.42417582"
                    fill="#F7F7F7"
                  ></path>
                  <rect
                    id="Rectangle-Copy"
                    fill={stronger}
                    x="26.6198245"
                    y="39.0950226"
                    width="1.425257"
                    height="11"
                    rx="0.7126285"
                  ></rect>
                </g>
              </g>
              <ellipse
                id="Oval-3"
                stroke="#C5C5CA"
                strokeWidth="5.34065934"
                cx="67.136565"
                cy="63.9736734"
                rx="66.8068947"
                ry="66.6440031"
              ></ellipse>
            </g>
            <g
              id="handle"
              transform="translate(139.777519, 132.905155) scale(-1, 1) rotate(-315.000000) translate(-139.777519, -132.905155) translate(129.377998, 97.300759)"
              fill="#F7F7F7"
              stroke="#C5C5CA"
              strokeWidth="5.34065934"
            >
              <path
                d="M5.0764022,10.1251027 L2.72879653,66.8137292 L10.154008,68.4242057 L17.5792195,66.8137292 L15.2316138,10.1251027 L5.0764022,10.1251027 Z"
                id="Rectangle-2"
              ></path>
              <path
                d="M2.70766711,2.67032967 L2.99935612,11.1742488 L17.30868,11.1742488 L17.600369,2.67032967 L2.70766711,2.67032967 Z"
                id="Rectangle-3"
              ></path>
            </g>
          </g>
          <ellipse id="shadow" fill={shadow} cx="109.442584" cy="177.032967" rx="39.1220096" ry="2.96703297"></ellipse>
        </g>
      </g>
    </svg>
  );
};
